import React, { useState, useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = 'https://erwuhmmbgaqwimseilfl.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVyd3VobW1iZ2Fxd2ltc2VpbGZsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY5MDM1OTgsImV4cCI6MjA0MjQ3OTU5OH0.Ld_Qh5CpWseySin0awMi5qkrnXVeXP6pNuXkMVIG72s';
const supabase = createClient(supabaseUrl, supabaseKey);

export default function ScoreDisplay() {
    const [displayScores, setDisplayScores] = useState({ teamL: 0, teamM: 0 });
    const [targetScores, setTargetScores] = useState({ teamL: 0, teamM: 0 }); // New state for target scores
  
    // Function to fetch the current score
    const fetchScore = useCallback(async () => {
      const { data, error } = await supabase
        .from('scores')
        .select('team_l, team_m')
        .single();
  
      if (error) {
        console.error('Error fetching score:', error);
      } else if (data) {
        const newScores = {
          teamL: data.team_l,
          teamM: data.team_m,
        };
  
        // Only animate if there's a change in the target scores
        if (newScores.teamL !== targetScores.teamL || newScores.teamM !== targetScores.teamM) {
          animateScoreChange(displayScores, newScores); // Pass current display and new scores
          setTargetScores(newScores); // Save the new target scores
          console.log('New scores:', newScores);
          console.log('Target scores:', targetScores);
        }
      }
    }, [displayScores, targetScores]); // Depend on displayScores and targetScores
  
    // Modified animateScoreChange to take starting and target points
    const animateScoreChange = useCallback((startScores, targetScores) => {
      const duration = 1000; // Duration of animation in ms
      const startTime = performance.now();
  
      const easingL = (t) => t * t; // Acceleration for teamL (quadratic)
      const easingM = (t) => t;     // Linear acceleration for teamM
  
      const step = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1); // Ensure progress is capped at 1
  
        // Apply easing functions
        const easedL = easingL(progress);
        const easedM = easingM(progress);
  
        // Update scores based on eased progress
        const updatedScores = {
          teamL: Math.round(startScores.teamL + (targetScores.teamL - startScores.teamL) * easedL),
          teamM: Math.round(startScores.teamM + (targetScores.teamM - startScores.teamM) * easedM),
        };
  
        setDisplayScores(updatedScores);
  
        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };
  
      requestAnimationFrame(step);
    }, []);
  
    // Set up polling every minute (60,000 ms)
    useEffect(() => {
      fetchScore(); // Call fetchScore initially
  
      const intervalId = setInterval(() => {
        fetchScore(); // Poll every minute
      }, 10000); // 60,000 milliseconds = 1 minute
  
      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [fetchScore]);
  
    return (
      <div className="score-display">
        <div className="score-container">
          <div className="score">{displayScores.teamL} - {displayScores.teamM}</div>
          {/* <div className="team-names">Team L vs Team M</div> */}
        </div>
      </div>
    );
  }
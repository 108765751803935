import React from 'react';
import ScoreDisplay from './ScoreDisplay';

function App() {
  return (
    <div className="App">
      <ScoreDisplay />
    </div>
  );
}

export default App;